import styled from "styled-components";
import { LngLat } from "react-map-gl";
import { normalizeLongitudeTo180range } from "./MapUtils";

const StyledCoordinates = styled.div`
  position: absolute;
  right: 0;
  background-color: hsla(0, 0%, 100%, 0.5);
  padding: 0 2px 0 2px;
  height: 20px;
  width: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface CoordinatesProps {
  mouseCoordinates: LngLat | undefined;
}
export const Coordinates = (props: CoordinatesProps) => {
  const formatCoordinates = () => {
    if (!props.mouseCoordinates) {
      return "";
    }

    return `${props.mouseCoordinates.lat.toFixed(
      4,
    )}, ${normalizeLongitudeTo180range(props.mouseCoordinates.lng).toFixed(4)}`;
  };

  return <StyledCoordinates>{formatCoordinates()}</StyledCoordinates>;
};
